<template>
    <div>
        <h1 class="page__title">Создать достопримечательность</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-12">
                    <label for="form-name" class="form-label">
                        Название
                        <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.title.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.title.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12">
                    <label for="form-description" class="form-label">Описание</label>
                    <CustomWyswig id="form-description" v-model="form.description" class="form-control w-full" />
                </div>

                <div class="relative col-span-6">
                    <label class="form-label">
                        Изображение
                        <sup v-if="v$.form.image.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.image.$errors.length" class="form-image-text-error">
                        {{ getOverrideMessage(v$.form.image.$errors[0].$message) }}
                    </label>
                    <UploadImageNew
                        v-model="form.image"
                        recommended-sizes="368x160px"
                        :has-error="!!v$.form.image.$errors.length"
                    ></UploadImageNew>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'attractions' }" class="btn btn-secondary w-24 mr-2 mb-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import { attractionsMixin } from '@/mixins/form/attractions-mixin';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: { UploadImageNew, Preloader, CustomWyswig },
    mixins: [errorResponse, formPreparation, attractionsMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                image: { required },
            },
        };
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                this.loading = false;
                const formData = this.formPreparation({ imagesAsObject: true });
                const { data } = await this.axios.post('/attractions', formData);
                if (flag) {
                    await this.$router.push({ name: 'attraction-edit', params: { id: data.data.id } });
                    this.$notify(data.message);
                } else {
                    await this.$router.push({ name: 'attractions' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style></style>
